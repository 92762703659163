import { TableBasic } from './TableBasic';
import { Tr } from './Tr';
import { Td } from './Td';
import { Th } from './Th';
import { Head } from './Head';
import { Body } from './Body';
Tr.displayName = "Table.Tr";
Td.displayName = "Table.Td";
Th.displayName = "Table.Th";
Head.displayName = "Table.Head";
Body.displayName = "Table.Body";
export { TableBasic as default, Tr, Td, Th, Head, Body };